const prepareRegionsData = (data) => {
  return data;
};

const prepareReasonsData = (data) => {
  return data;
};

const prepareDynamicData = (data) => {
  return data;
};

/**
 * @param {any} data
 */
const prepareSdrData = (data) => {
  return {
    d: data,
    o: {
      test: "test",
    },
  };
};

/**
 * @param {any} data
 */
const prepareSdrAndDynData = (data) => {
  return data;
};

const prepareByAgeData = ([dictionaries, data, payload]) => {
  const { ageGroup, reasons } = dictionaries.data;
  let items = data.data.filter((d, i) => i !== 0);

  items = ageGroup.map((d) => {
    const res = {};
    payload.params.reasons.forEach((b) => {
      const val = items.find((v) => v[6] === d.id && v[5] === b);
      res[b] = val ? +val[7] : 0;
    });
    res.ageGroup = d.comment;
    return res;
  });

  return {
    d: items,
    o: {
      key: "ageGroup",
      legendData: reasons,
    },
  };
};

export {
  prepareSdrData,
  prepareByAgeData,
  prepareDynamicData,
  prepareRegionsData,
  prepareReasonsData,
  prepareSdrAndDynData,
};
