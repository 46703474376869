import isEqual from 'deep-equal'
import requestFactory from '../darkin/newRequest'
import {
  loadRegionsData,
  loadByAgeData,
  loadReasonsData,
  loadDynamicData,
  checkAndLoadData,
  checkAndLoadOne,
} from './parts/ly.functions'

const request = requestFactory()

const parts = ['regions', 'by-age', 'reasons', 'dynamic']
const initialData = () => {
  const propsArray = [
    ['reasonId', 'year', 'ageGroupIds', 'populationGroupId', 'sexId'],
    ['regionId', 'reasonIds', 'year', 'populationGroupId', 'sexId'],
    ['reasonIds', 'reasonsCompareList'],
    ['regionId', 'reasonId', 'ageGroupIds', 'populationGroupId', 'sexId'],
  ]
  const actions = [loadRegionsData, loadByAgeData, loadReasonsData, loadDynamicData]
  const template = parts.reduce((d, v, i) => {
    const props = propsArray[i],
      action = actions[i]
    d[v] = {
      isActual: false,
      props,
      additionals: null,
      currentFilterValues: null,
      useful: null,
      action,
      plot: null, // От этого нужно будет избавиться. Сейчас влияет на реактивность
    }
    return d
  }, {})
  return template
}

const initState = () => {
  return {
    state: {
      oldFilters: null,
      filters: {
        regionId: 94, // Default Russia
        reasonId: 306, // Default All
        sexId: 3, // Default All
        populationGroupId: 3, // Default All
        year: null, // Need to be transform to last year
        reasonIds: [308, 313, 323, 325, 328, 333, 341, 353, 355, 359, 361, 372, 373],
        ageGroupIds: [],
        reasonsCompareList: [{ ageGroups: [], populationGroup: 3, region: 94, sex: 3, year: 2020 }],
      },
      dictionaries: {
        reasons: [],
        ageGroup: [],
        regions: [],
        populationGroup: [],
        sex: [],
        years: [],
      },
      data: initialData(),
      additional: {
        reasonsParams: [],
      },
      forceRefresh: false, // Обновлять принудительно (пока не используется)
      version: null, // Пока не используется
    },
  }
}

const { state } = initState()
const prepare = (context, payload) => {
  return { ...context.state.filters, ...payload }
}

const actions = {
  setReasonsCompareListParamsAndLoadData(context, payload = null) {
    // If null - remove all, if number - remove by id, if object - push to array
    let newReasonsCompareList = []
    const reasonsCompareList = context.state.filters.reasonsCompareList
    if (typeof payload === 'number') {
      newReasonsCompareList = [...reasonsCompareList].filter((d, i) => i !== payload)
    } else if (payload) {
      newReasonsCompareList = [...reasonsCompareList]
      newReasonsCompareList.push(payload)
    }
    return context.dispatch('setFiltersAndLoadData', { reasonsCompareList: newReasonsCompareList })
  },
  async setFiltersAndLoadData(context, payload) {
    const newFilters = prepare(context, payload)
    if (isEqual(newFilters, context.state.filters)) {
      return null
    }
    context.commit('SET_FILTERS', newFilters)
    let result = await checkAndLoadData(context)
    context.commit('CLEAR_OLD_FILTERS')
    return result
  },
  loadAll(context) {
    return checkAndLoadData(context)
  },
  loadOne(context, type) {
    return checkAndLoadOne(context, type)
  },
  async loadDictionaries(context) {
    const { data } = await request('/api/clear-dictionaries')
    context.commit('SET_DICTIONARIES', data)
    return data
  },
  setReasonsParams(context, reasonsParams) {
    context.commit('SET_REASONS_PARAMS', reasonsParams)
  },
  //  ...specActions,
}
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, initState().state)
  },
  SET_FILTERS(state, payload) {
    state.oldFilters = state.filters
    state.filters = payload
  },
  CLEAR_OLD_FILTERS(state) {
    state.oldFilters = null
  },
  SET_DICTIONARIES(state, payload) {
    state.dictionaries = payload
  },
  SET_REGIONS_DATA(state, payload) {
    state.data['regions'].useful = payload.result
    state.data['regions'].currentFilterValues = payload.params
    state.data['regions'].additionals = payload.additionals
  },
  SET_REASONS_DATA(state, payload) {
    state.data['reasons'].useful = payload.result
    state.data['reasons'].currentFilterValues = payload.params
    state.data['reasons'].additionals = payload.additionals
  },
  SET_DYNAMIC_DATA(state, payload) {
    state.data['dynamic'].useful = payload.result
    state.data['dynamic'].currentFilterValues = payload.params
    state.data['dynamic'].additionals = payload.additionals
  },
  SET_BY_AGE_DATA(state, payload) {
    state.data['by-age'].useful = payload.result
    state.data['by-age'].currentFilterValues = payload.params
    state.data['by-age'].additionals = payload.additionals
    state.data['by-age'].plot = payload.plot
  },
  SET_REASONS_PARAMS(state, payload) {
    state.additional.reasonsParams = payload
  },
}
const getters = {
  getFilters: state => state.filters,
  getDictionaries: state => state.dictionaries,
  getRegionsData: state => state.data['regions'].useful,
  getRegionsAdditionals: state => state.data['regions'].additionals,
  getByAgeData: state => state.data['by-age'].useful,
  getByAgePlot: state => state.data['by-age'].plot,
  getReasonsData: state => state.data['reasons'].useful,
  getDynamicData: state => state.data['dynamic'].useful,
  getReasonsParams: state => state.additional.reasonsParams,
  getReasonsAdditionals: state => state.data['reasons'].additionals,
  getBarsParams: state => state.filters.reasonsCompareList,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
