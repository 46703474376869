export function getNewByReasons(state) {
  const newByReasons = state.dashboardReasons.display.map(b => {
    const v = state.data.byReasons.find(d => d.id === b)
    if (v) {
      return { ...v, value: Number(v.value) }
    } 
  })
  const otherReasons = {
    id: state.dashboardReasons.otherID,
    name: 'Другие причины',
    value: 0,
  }
  state.data.byReasons.forEach(d => {
    if (!state.dashboardReasons.display.includes(d.id)) otherReasons.value += Number(d.value)
  })
  newByReasons.push(otherReasons)
  return newByReasons
}

export function getNewByAges(state) {
  const newByAges = []
  const ordersAges = Array(19)

  state.data.byAges.forEach(d => {
    if (!ordersAges[d.ageGroupId - 1]) {
      ordersAges[d.ageGroupId - 1] = {
        ...d,
        reasonId: state.dashboardReasons.otherID,
        reasonName: 'Другие причины',
        value: 0,
      }
    }
    if (!state.dashboardReasons.display.includes(d.reasonId)) {
      ordersAges[d.ageGroupId - 1].value += +d.value
    } else {
      newByAges.push({ ...d, value: +d.value })
    }
  })
  newByAges.push(...ordersAges)
  return newByAges
}

export function getNewReasons(state) {
  let reasons = []
  let orders = false
  state.data.params.reasons.forEach(d => {
    if (state.dashboardReasons.display.includes(d)) {
      reasons.push(d)
    } else {
      orders = true
    }
  })
  if (orders) {
    reasons.push(state.dashboardReasons.otherID)
  }
  return reasons
}