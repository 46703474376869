const actionListner = (options = {}) => {
  if (!options.moduleName) {
    options.moduleName = 'actionListner'
  }
  if (!options.delay) {
    options.delay = 500
  }

  let counter = 0
  let checkPending = store => {
    if (counter > 0) {
      if (!store.getters[`${options.moduleName}/pending`]) {
        store.commit(`${options.moduleName}/pending`, true)
      }
    } else {
      if (store.getters[`${options.moduleName}/pending`]) {
        store.commit(`${options.moduleName}/pending`, false)
      }
      if (options.pendingMutation && store.getters[`${options.moduleName}/delayedPending`]) {
        store.commit(options.pendingMutation, false)
      }
      if (store.getters[`${options.moduleName}/delayedPending`]) {
        store.commit(`${options.moduleName}/delayedPending`)
      }
    }
  }

  // options.pendingMutation - мутация для индикатора ожидания
  // options.hasErrorMutation - мутация для индикатора наличия ошибки
  return store => {
    store.subscribeAction({
      before: action => {
        if (action.type === `${options.moduleName}/initDelayedPending`) return
        counter++
        checkPending(store)
        store.dispatch(`${options.moduleName}/initDelayedPending`)
      },
      after: action => {
        if (action.type === `${options.moduleName}/initDelayedPending`) return
        counter--
        checkPending(store)
      },
      error: action => {
        if (action.type === `${options.moduleName}/initDelayedPending`) return
        counter--
        checkPending(store)
        if (options.hasErrorMutation) {
          store.commit(options.hasErrorMutation, true)
        }
      },
    })

    if (options.pendingMutation) {
      store.commit(options.pendingMutation, false)
    }
    if (options.hasErrorMutation) {
      store.commit(options.hasErrorMutation, false)
    }

    store.registerModule(options.moduleName, {
      namespaced: true,
      state: () => ({
        pending: false,
        delayedPending: false,
        counter: 0,
      }),
      mutations: {
        pending(state, payload) {
          state.pending = payload
        },
        delayedPending(state) {
          state.delayedPending = state.pending
          if (options.pendingMutation) {
            store.commit(options.pendingMutation, state.delayedPending)
          }
        },
      },
      actions: {
        initDelayedPending(context) {
          return new Promise(r =>
            setTimeout(() => {
              if (context.state.delayedPending != context.state.pending) {
                context.commit('delayedPending')
              }
              r()
            }, options.delay)
          )
        },
      },
      getters: {
        delayedPending(state) {
          return state.delayedPending
        },
        pending(state) {
          return state.pending
        },
      },
    })
  }
}

export default actionListner
