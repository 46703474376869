import request from '../darkin/newRequest'
const r = request({ cancelable: true })

const indicators = {
  namespaced: true,
  state: () => ({
    indicatorsData: [],
    indicatorsByYears: [],
    params: {
      reason: 333,
      year: null,
    },
  }),
  mutations: {
    setIndicators(state, indicatorsData) {
      state.indicatorsData = indicatorsData
    },
    setReason(state, reason) {
      state.reason = reason
    },
    setIndicatorsByYears(state, indicatorsByYears) {
      state.indicatorsByYears = indicatorsByYears
    },
    setParams(state, payload) {
      state.params = payload
    },
    RESET_STATE(state) {
      Object.assign(state, indicators.state())
    },
  },
  actions: {
    loadIndicators(context, payload) {
      const req = { ...payload }
      req['indicatorReason'] = payload['reason']
      return r('/api/indicators', 'POST', req).then(
        r => {
          context.commit('setIndicators', r.data.indicators)
          context.commit('setIndicatorsByYears', r.data.indicatorsByYears)
          context.commit('setReason', r.data.reason)
        },
        e => console.log('There are an error!', e)
      )
    },
    initialLoading(context) {
      return context.dispatch('loadIndicators', context.state.params)
    },
    setParams(context, payload) {
      context.commit('setParams', { ...context.state.params, ...payload })
      return context.dispatch('loadIndicators', context.state.params)
    },
  },
  getters: {
    getIndicators(state) {
      return state.indicatorsData
    },
    getReason(state) {
      return state.reason
    },
    getParams(state) {
      return state.params
    },
    getIndicatorsByYears(state) {
      return state.indicatorsByYears
    },
    getIndicatorsByYear: state => iId => {
      const values = state.indicatorsByYears.filter(d => d.uId === iId)
      return values
    },
  },
}

export default indicators
