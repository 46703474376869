const service = {
  state: () => ({
    dialogState: false,
    reasonsParams: [],
  }),
  mutations: {
    setDialogState: (state, value) => (state.dialogState = value),
    setReasonsParams: (state, value) => (state.reasonsParams = value),
    RESET_STATE(state) {
      Object.assign(state, service.state())
    },
  },
  actions: {},
  getters: {
    getDialogState: state => state.dialogState,
    getReasonsParams: state => state.reasonsParams,
  },
}

export default service
