import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import indicators from './indicators'
import lostYears from './lostYears'
import newLostYears from './newLostYears'
import actionListner from '../darkin/vuexProgressPlugin'
import requestFactory from '../darkin/newRequest'
import dashboard from './dashboard'
import global from './global'
import service from './service'
import tooltip from './tooltip'

Vue.use(Vuex)

const modules = {
  indicators,
  ly: lostYears,
  ly2: newLostYears,
  dbd: dashboard,
  //    as: authSupport,
  tt: tooltip,
  global,
  service,
}

const loginRequest = requestFactory()

export default new Vuex.Store({
  plugins: [
    createPersistedState({ paths: ['token', 'global'] }),
    actionListner({
      pendingMutation: 'setPending',
    }),
  ],
  state: {
    pending: false,
    token: null,
    isParamsPanelOpen: true,
    isLogged: true,
  },
  mutations: {
    setPending(state, payload) {
      state.pending = payload
    },
    changeParamsPanelState(state) {
      state.isParamsPanelOpen = !state.isParamsPanelOpen
    },
    setLogIn(state) {
      state.isLogged = true
    },
    setLogOut(state) {
      state.isLogged = false
    },
  },
  actions: {
    checkAndResetState(context) {
      if (process.env.VUE_APP_VERSION !== context.state.global.version) {
        for (const moduleIndex in modules) {
          if (['dashboard', 'service'].indexOf(moduleIndex) !== -1) continue
          try {
            context.commit(`${moduleIndex}/RESET_STATE`)
          } catch (e) {
            console.log(e)
          }
        }
        context.commit(`global/SET_VERSION`)
      }
    },
    login(context, payload) {
      const { name, password } = payload
      return loginRequest('/auth/login', 'post', { name, password })
        .then(r => {
          context.commit('setToken', r.data.token)
          return r
        })
        .catch(e => {
          throw e
        })
    },
    logout(context) {
      return loginRequest('/auth/logout', 'post')
        .then(r => {
          context.commit('setToken', null)
          context.commit('ly/RESET_STATE')
          return r
        })
        .catch(e => {
          throw e
        })
    },
  },
  getters: {
    getPending(state) {
      return state.pending
    },
    getToken(state) {
      return state.token
    },
    isAuthenticated(state) {
      return !!state.token
    },
  },
  modules,
})
