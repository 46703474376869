import requestFactory from '../../darkin/newRequest'
//import isEqual from 'deep-equal'

const requestRegions = requestFactory({ cancelable: true })
const requestByAge = requestFactory({ cancelable: true })
const requestReasons = requestFactory({ cancelable: true })
const requestDynamic = requestFactory({ cancelable: true })

export const loadRegionsData = async (context, payload) => {
  const { data } = await requestRegions('/api/v2/regions', 'POST', payload)
  context.commit('SET_REGIONS_DATA', data)
  return data
}
export const loadByAgeData = async (context, payload) => {
  const { data } = await requestByAge('/api/v2/by-age', 'POST', payload)
  data.plot = prepareByAgeData(data)
  context.commit('SET_BY_AGE_DATA', data)
  return data
}
export const loadReasonsData = async (context, payload) => {
  const { data } = await requestReasons('/api/v2/reasons', 'POST', payload)
  context.commit('SET_REASONS_DATA', data)
  return data
}
export const loadDynamicData = async (context, payload) => {
  const { data } = await requestDynamic('/api/v2/dynamic', 'POST', payload)
  context.commit('SET_DYNAMIC_DATA', data)
  return data
}

export const checkAndLoadData = context => {
  const { data } = context.state

  const results = []
  let loadPromise
  for (const type in data) {
    loadPromise = checkAndLoadOne(context, type)
    if (loadPromise) {
      results.push(loadPromise)
    }
  }
  return Promise.allSettled(results)
}

export const checkAndLoadOne = (context, type) => {
  const { filters, data } = context.state
  // const compareProps = propsArray => {
  //   const [o1, o2] = [{}, {}]
  //   propsArray.forEach(d => {
  //     o1[d] = data[type].currentFilterValues ? data[type].currentFilterValues[d] : null
  //     o2[d] = filters[d]
  //   })
  //   return isEqual(o1, o2)
  // }
  const getClearObject = propsArray => {
    const o = {}
    propsArray.forEach(d => {
      o[d] = filters[d]
    })
    return o
  }
  let { props, action } = data[type]
  //  if (!compareProps(props)) {
  return action(context, getClearObject(props))
  //  }
  //  return null
}

const prepareByAgeData = payload => {
  // state.data['by-age'].useful = payload.result
  // state.data['by-age'].currentFilterValues = payload.params
  // state.data['by-age'].additionals = payload.additionals
  const { ageGroups, reasons } = payload.additionals
  const { result } = payload
  let items = result.filter((d, i) => i !== 0)

  items = ageGroups.map(d => {
    const res = {}
    reasons.forEach(b => {
      const val = items.find(v => v[6] === d.id && v[5] === b.id)
      res[b.id] = val ? +val[7] : 0
    })
    res.ageGroup = d.name
    return res
  })

  return {
    d: items,
    o: {
      key: 'ageGroup',
      legendData: reasons,
    },
  }
}
