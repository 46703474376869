<template>
  <div class="tooltip__container" :style="style" ref="tooltip">
    <div v-if="computedTooltip.title" class="tooltip__title">{{ computedTooltip.title }}</div>
    <div v-if="computedTooltip.text" class="tooltip__text">{{ computedTooltip.text }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      defaultProps: {
        show: false,
        top: 0,
        left: 0,
        height: 0,
        width: 350,
        title: '',
        text: '',
      },
      margin: {
        bottom: 10,
      },
      calcHeight: 40,
    }
  },
  watch: {
    computedTooltip: {
      handler() {
        this.$nextTick(() => {
          this.calcHeight = this.$refs.tooltip.getBoundingClientRect().height
        })
      },
    },
  },
  computed: {
    ...mapGetters({ getTooltip: 'tt/getTooltip' }),
    computedTooltip() {
      return Object.assign(this.defaultProps, this.getTooltip)
    },
    style() {
      const { show, top, left, width } = this.computedTooltip
      const { margin, calcHeight } = this
      const displayTop = top - calcHeight - margin.bottom
      const displayLeft = left - width / 2
      return {
        opacity: show ? 0.95 : 0,
        top: `${displayTop}px`,
        left: `${displayLeft}px`,
        width: `${width}px`,
      }
    },
  },
}
</script>

<style scoped>
.tooltip__container {
  position: absolute;
  padding: 6px;
  font: 12px sans-serif;
  background: grey;
  color: white;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
  transition: opacity ease-in-out 0.2s 0.2s;
  z-index: 99;
}

.tooltip__title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
