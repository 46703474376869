import requestFactory from '../darkin/newRequest'
import axios from 'axios'
import {
  prepareDynamicData,
  prepareByAgeData,
  prepareRegionsData,
  prepareReasonsData,
  prepareSdrData,
  prepareSdrAndDynData,
} from '../darkin/plotPrepares'

const template = {
  i: 0, // Индекс
  w: 550, // Ширина
  h: 350, // Высота
  aw: false, // Автоматическая ширина
  t: 'unknown', // Тип
  m: { l: 20, t: 20, r: 20, b: 20 }, // Границы
  d: [], // Данные
  o: {}, // Опции
}

const initState = () => {
  return {
    privates: {
      loadDictionariesPromise: null,
      loadVersionPromise: null,
      request: requestFactory(),
      requestRegions: requestFactory({ cancelable: true }),
      requestReasons: requestFactory({ cancelable: true }),
      requestDynamic: requestFactory({ cancelable: true }),
      requestByAge: requestFactory({ cancelable: true }),
      requestSdr: requestFactory({ cancelable: true }),
      requestSdrAndDyn: requestFactory({ cancelable: true }),
    },
    state: {
      dictionaries: {
        reasons: [],
        ageGroup: [],
        regions: [],
        populationGroup: [],
        sex: [],
        years: [],
      },
      params: {
        reason: 306,
        operationalReason: 308,
        region: 94,
        sex: 3,
        populationGroup: 3,
        year: 2019,
        reasons: [],
        ageGroups: [],
      },
      additionals: {
        byAgeRelative: false,
        firstLevel: true,
      },
      barsParams: [],
      plots: [], // Данные графиков по шаблону
      hasError: false,
      version: '00000000',
      reasons: [],
    },
  }
}

const { state, privates } = initState()

const actions = {
  loadDictionaries(context) {
    if (!privates.loadDictionariesPromise) {
      privates.loadDictionariesPromise = privates
        .request('/api/clear-dictionaries')
        .then(r => {
          context.commit('SET_DICTIONARIES', r.data)
          return r
        })
        .catch(e => {
          context.commit('SET_ERROR_STATE', true)
          // Если произошла ошибка - обнуляем промисс запроса чтобы повторить запрос при следующем обращении
          privates.loadDictionariesPromise = null
          throw e
        })
    }
    return privates.loadDictionariesPromise
  },
  loadVersion(context) {
    if (!privates.loadVersionPromise) {
      privates.loadVersionPromise = privates
        .request('/api/version')
        .then(r => {
          if (r.data.version !== context.state.version) {
            context.commit('RESET_STATE')
            context.commit('SET_VERSION', r.data.version)
          }
          return r
        })
        .catch(e => {
          context.commit('SET_ERROR_STATE', true)
          // Если произошла ошибка - обнуляем промисс запроса чтобы повторить запрос при следующем обращении
          privates.loadVersionPromise = null
          throw e
        })
    }
    return privates.loadVersionPromise
  },
  loadRegionsData(context, payload) {
    const regionsData = privates.requestRegions('/api/regions', 'POST', payload)
    const results = Promise.all([context.dispatch('loadDictionaries'), regionsData])
    return results
      .then(a => {
        const data = prepareRegionsData(a[1].data)
        context.commit('MODIFY_UNIQUE_PLOT', { type: 'regions', item: { d: data } })
        return data
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          throw e
        }
      })
  },
  loadReasonsData(context, payload) {
    const reasonsData = privates.requestReasons('/api/data', 'POST', payload)
    const results = Promise.all([context.dispatch('loadDictionaries'), reasonsData])
    return results
      .then(a => {
        const data = prepareReasonsData(a[1].data)
        context.commit('MODIFY_UNIQUE_PLOT', { type: 'reasons', item: { d: data } })
        context.commit('SET_REASONS', data)
        return data
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          //          throw e;
        }
      })
  },
  loadDynamicData(context, payload) {
    const dynamicData = privates.requestDynamic('/api/dynamic', 'POST', payload)
    const results = Promise.all([context.dispatch('loadDictionaries'), dynamicData])
    return results
      .then(a => {
        const data = prepareDynamicData(a[1].data)
        context.commit('MODIFY_UNIQUE_PLOT', { type: 'dynamic', item: { d: data } })
        return data
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          throw e
        }
      })
  },
  loadByAgePlotData(context, payload) {
    const byAgeData = privates.requestByAge('/api/data/by-age-plot', 'POST', payload.params)
    const results = Promise.all([context.dispatch('loadDictionaries'), byAgeData])
    return results
      .then(a => {
        const { d, o } = prepareByAgeData([...a, payload])
        context.commit('MODIFY_UNIQUE_PLOT', { type: 'by-age', item: { d, o } })
        return d
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          throw e
        }
      })
  },
  loadSdrData(context, payload) {
    const sdrData = privates.requestSdr('/api/sdr', 'POST', payload)
    const results = Promise.all([context.dispatch('loadDictionaries'), sdrData])
    return results
      .then(a => {
        const { d, o } = prepareSdrData(a[1].data)
        context.commit('MODIFY_UNIQUE_PLOT', { type: 'sdr', item: { d, o } })
        return d
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          throw e
        }
      })
  },
  loadSdrAndDynData(context, payload) {
    const sdrAndDynData = privates.requestSdrAndDyn('/api/sdr-and-dyn', 'POST', payload)
    const results = Promise.all([context.dispatch('loadDictionaries'), sdrAndDynData])
    return results
      .then(a => {
        const data = prepareSdrAndDynData(a[1].data)
        context.commit('MODIFY_UNIQUE_PLOT', { type: 'sdr-and-dyn', item: { d: data } })
        return data
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          throw e
        }
      })
  },
}
const mutations = {
  SET_DICTIONARIES(state, payload) {
    state.dictionaries = payload
  },
  SET_VERSION(state, payload) {
    state.version = payload
  },
  SET_ERROR_STATE(state, payload) {
    state.hasError = payload
  },
  SET_PARAMS(state, payload) {
    state.params = Object.assign({}, state.params, payload)
  },
  SET_REASONS(state, payload) {
    state.reasons = payload
  },
  REMOVE_PARAM(state, payload) {
    delete state.params[payload]
  },
  MODIFY_UNIQUE_PLOT(state, payload) {
    const { type, item } = payload
    const i = state.plots.findIndex(d => d.t === type)
    if (i < 0) {
      state.plots.push(Object.assign({}, template, item, { t: type }))
    } else {
      Object.assign(state.plots[i], item)
    }
  },
  REMOVE_PLOT(state, payload) {
    state.plots.splice(payload, 1)
  },
  CLEAR_BARS_PARAMS(state) {
    state.barsParams = []
  },
  REMOVE_BARS_PARAMS(state, id) {
    state.barsParams = state.barsParams.filter((d, i) => i !== id)
  },
  ADD_BARS_PARAMS(state, payload) {
    state.barsParams.push(payload)
  },
  SET_BY_AGE_RELATIVE(state, payload) {
    state.additionals.byAgeRelative = payload
  },
  SET_FIRST_LEVEL(state, payload) {
    state.additionals.firstLevel = payload
  },
  RESET_STATE(state) {
    Object.assign(state, initState().state)
    Object.assign(privates, initState().privates)
  },
}
const getters = {
  getDictionaries(state) {
    return state.dictionaries
  },
  getVersion(state) {
    return state.version
  },
  getParams(state) {
    return state.params
  },
  getBarsParams(state) {
    return state.barsParams
  },
  getHasError(state) {
    return state.hasError
  },
  getPlots(state) {
    return state.plots
  },
  getPlotsCount(state) {
    return state.plots.length
  },
  getPlot(state) {
    return item => state.plots[item]
  },
  getPlotByType(state) {
    return type => state.plots.find(d => d.t === type)
  },
  getRegionsPlot(state) {
    return state.plots.find(d => d.t === 'regions')
  },
  getReasonsPlot(state) {
    return state.plots.find(d => d.t === 'reasons')
  },
  getDynamicPlot(state) {
    return state.plots.find(d => d.t === 'dynamic')
  },
  getDynamicData(state) {
    const dynamicPlot = state.plots.find(d => d.t === 'dynamic')
    return dynamicPlot ? dynamicPlot.d : []
  },
  getByAgePlot(state) {
    return state.plots.find(d => d.t === 'by-age')
  },
  getSdrPlot(state) {
    return state.plots.find(d => d.t === 'sdr')
  },
  getSdrAndDynPlot(state) {
    return state.plots.find(d => d.t === 'sdr-and-dyn')
  },
  getByAgeRelative(state) {
    return state.additionals.byAgeRelative
  },
  getFirstLevel(state) {
    return state.additionals.firstLevel
  },
  getReasons(state) {
    return state.reasons
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
