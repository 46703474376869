<template>
  <v-app>
    <router-view />
    <AppTooltip />
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import AppTooltip from './AppTooltip.vue'
import { formatDefaultLocale } from 'd3'

export default {
  name: 'App',
  components: {
    AppTooltip,
  },
  methods: {
    ...mapActions({
      loadDictionaries: 'ly2/loadDictionaries',
      checkAndResetState: 'checkAndResetState',
    }),
  },
  created() {
    formatDefaultLocale({
      decimal: ',',
      thousands: ' ',
      grouping: [3],
    })
    this.checkAndResetState()
    this.loadDictionaries()
  },
}
</script>
