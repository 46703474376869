const initState = () => ({
  token: null,
  refresh_token: null,
  redirect: null,
  version: null,
})

const service = {
  namespaced: true,
  state: initState(),
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_REFRESH_TOKEN(state, refresh_token) {
      state.refresh_token = refresh_token
    },
    SET_REDIRECT(state, redirect) {
      state.redirect = redirect
    },
    SET_VERSION(state) {
      state.version = process.env.VUE_APP_VERSION
    },
    RESET_STATE(state) {
      Object.assign(state, initState())
    },
  },
  actions: {
    setToken({ commit, state }, token) {
      if (token !== state.token) commit('SET_TOKEN', token)
      return token
    },
    setRefreshToken({ commit, state }, refresh_token) {
      if (refresh_token !== state.refresh_token) commit('SET_REFRESH_TOKEN', refresh_token)
      return refresh_token
    },
    setRedirect({ commit, state }, redirect) {
      if (redirect !== state.redirect) commit('SET_REDIRECT', redirect)
      return redirect
    },
  },
  getters: {
    getToken: state => state.token,
    getRefreshToken: state => state.refresh_token,
    getRedirect: state => state.redirect,
  },
}

export default service
