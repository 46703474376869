import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashbord',
    component: () => import('../views/Dashbord.vue'),
  },
  {
    path: '/regions',
    name: 'Regions',
    component: () => import('../views/Regions.vue'),
  },
  {
    path: '/reasons',
    name: 'Reasons',
    component: () => import('../views/Reasons.vue'),
  },
  {
    path: '/by-age',
    name: 'ByAge',
    component: () => import('../views/ByAge.vue'),
  },
  {
    path: '/dynamic',
    name: 'Dynamic',
    component: () => import('../views/Dynamic.vue'),
  },
  // {
  //   path: '/operational-indicators',
  //   name: 'OperationalIndicators',
  //   component: () => import('../views/OperationalIndicators.vue'),
  // },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () => import('../views/Login.vue'),
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
