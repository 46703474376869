import requestFactory from '../darkin/newRequest'
import { getNewByReasons, getNewByAges, getNewReasons } from './dashbordHelpers'

const request = requestFactory({ cancelable: true })

const actionDelay = 450

const dashboard = {
  namespaced: true,
  state: () => ({
    params: {
      year: null,
      reasons: [],
      sexId: 3,
      populationGroupId: 3,
      regions: [],
    },
    dashboardReasons: {
    /*
      Порядок сортировки dashboardReasons.display задан жестко: По убыванию в 2019.
      Для других лет не пересоритровываем, чтобы не менялсяпорядок легенды и цвета 
    */
    /*
      Кажется dashboardReasons.other можно рассчитать, а не хардкодить
      Но пока менять только вместе. Чтобы уменьшить вероятность ошибки положил в один объект
    */
      display: [333, 313, 361, 353, 308, 341, 373],
      other: [328, 359, 323, 372, 355, 325],
      otherID: 9999,
    },
    data: {},
    calculatedData: {},
    calculatedParams: {
      year: null,
      reasons: [],
      sexId: 3,
      populationGroupId: 3,
      regions: [],
    },
  }),
  mutations: {
    SET_DATA(state, payload) {
      state.data = payload

      if (
        !state.data ||
        !state.data.byReasons ||
        !state.data.byReasons.length ||
        !state.data.byAges.length
      ) {
        state.calculatedData = { ...state.data }
      }
      
      state.calculatedData = Object.assign({}, state.data, {
        byReasons: getNewByReasons(state),
        byAges: getNewByAges(state),
        params: { ...state.data.params, reasons: getNewReasons(state) },
      })
    },
    SET_PARAMS(state, payload) {
      state.params = payload
      let reasonIds = []
      state.params.reasons.forEach(d =>
        d === state.dashboardReasons.otherID ? reasonIds.push(...state.dashboardReasons.other) : reasonIds.push(d)
      )
      state.calculatedParams = { ...state.params, reasons: reasonIds }
    },
    SET_PARAM(state, param) {
      Object.assign(state.params, param)
      if (param.reasons) {
        let reasonIds = []
        state.params.reasons.forEach(d =>
          d === state.dashboardReasons.otherID ? reasonIds.push(...state.dashboardReasons.other) : reasonIds.push(d)
        )
        state.calculatedParams.reasons = reasonIds
      } else {
        Object.assign(state.calculatedParams, param)
      }
    },
    RESET_STATE(state) {
      Object.assign(state, dashboard.state())
    },
  },
  actions: {
    loadData({ commit, state, getters }, params) {
      return new Promise((resolve, reject) => {
        if (params) {
          commit('SET_PARAMS', params)
        }
        request('/api/dashboard-data', 'POST', getters.getGoodParams)
          .then(r => {
            if (JSON.stringify(state.data) !== JSON.stringify(r.data)) {
              commit('SET_DATA', r.data)
              if (!state.params.year) {
                commit('SET_PARAMS', { ...state.params, year: r.data.params.year })
              }
            }
            resolve(r.data)
          })
          .catch(e => reject(e))
      })
    },
    async setParams({ commit, dispatch }, params) {
      commit('SET_PARAMS', params)
      await new Promise(r => setTimeout(() => r(), actionDelay))
      return dispatch('loadData')
    },
    async setParam({ commit, dispatch }, param) {
      commit('SET_PARAM', param)
      await new Promise(r => setTimeout(() => r(), actionDelay))
      return dispatch('loadData')
    },
  },
  getters: {
    getDisplayedReasons: state => [...state.dashboardReasons.display, state.dashboardReasons.otherID],
    getParams: state => state.params,
    getGoodParams: state => state.calculatedParams,
    getData: state => state.data,
    getGoodData: state => state.calculatedData,
    getOtherID: state => state.dashboardReasons.otherID,
  },
}

export default dashboard
