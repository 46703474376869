const tooltip = {
  namespaced: true,
  state: () => ({
    tooltip: {
      show: false,
      top: 0,
      left: 0,
      title: '',
      text: '',
    },
  }),
  mutations: {
    SET_TOOLTIP(state, payload) {
      state.tooltip = payload
    },
    setTooltip(state, payload) {
      state.tooltip = { ...payload }
    },
    RESET_STATE(state) {
      Object.assign(state, tooltip.state())
    },
  },
  actions: {
    setTooltip({ commit }, params) {
      commit('SET_TOOLTIP', { ...params })
    },
  },
  getters: {
    getTooltip: state => state.tooltip,
  },
}

export default tooltip
